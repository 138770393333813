import React from 'react'

// components
import MiniCalendar from './miniCalendar'
import Filter       from './filter'
import TimeRange    from './timeRange'
import ResourceList from './resourceList'
import Checkbox     from '@shared/checkbox/checkbox'

const CalendarLeft = ({ 
  calendar,
  resources,
  setResources,
  currentDate,
  showWeekends,
  setShowWeekends,
  slotMinTime,
  slotMaxTime,
  setSlotMinTime,
  setSlotMaxTime,
  autoRefresh,
  setAutoRefresh,
  filter,
  setFilter,
  filterOpen,
  setFilterOpen,
  setShowPopup,
  partsStatusPermitted
}) =>
  <div className="calendar-left">
    <div className="fixed-controls">
      <MiniCalendar
        calendar     = {calendar}
        currentDate  = {currentDate}
        showWeekends = {showWeekends}
      />

      <TimeRange
        slotMinTime    = {slotMinTime}
        slotMaxTime    = {slotMaxTime}
        setSlotMinTime = {setSlotMinTime}
        setSlotMaxTime = {setSlotMaxTime}
      />

      <Checkbox
        checked   = {showWeekends}
        id        = "weekend-checkbox"
        onChange  = {()=>setShowWeekends(!showWeekends)}
        label     = {I18n.t("calendar.weekends")}
        className = "mt-3 form-group"
      />

      <Checkbox
        checked   = {autoRefresh}
        id        = "auto-refresh-checkbox"
        onChange  = {()=>setAutoRefresh(!autoRefresh)}
        label     = {I18n.t("calendar.auto_refresh")}
        className = "form-group"
      />

      <Filter {...
        {
          filter,
          setFilter,
          resources,
          setResources,
          partsStatusPermitted,
          filterOpen,
          setFilterOpen
        }
      } />
      <ResourceList {...{resources, setResources, setShowPopup }} />
    </div>
  </div>

export default CalendarLeft
