// Modules
import React           from 'react'
import SharedFlatpickr from "@shared/flatpickr"

// Components
import TechnicianSelect from '@shared/technician_select'

// Helpers
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Constants
// const FILTERS = [
//   { name: I18n.t('workorders.statuses.Scheduled'), value: 'Scheduled' },
//   { name: I18n.t('workorders.statuses.In progress'), value: 'In progress' },
//   { name: I18n.t('workorders.statuses.Closed'), value: 'Closed' }
// ]

export default class TimesheetFilters extends React.Component {

  triggerPrint = event => {
    event.preventDefault()
    window.print()
  }

  render() {
    const {
      showFilters,
      toggleFilterWindow,

      teams,
      csvPath,
      overtimePath,
      onSelectUser,

      onSelectDate,
      range
    } = this.props

    const hide    = { display: 'none' }
    return  (
      <div className="no-print">
        {/* Toggle filters */ }
        <div className="timesheet__filters__toggle" onClick={toggleFilterWindow}>
          <div className={`timesheet__sheet__arrow  ${showFilters ? 'timesheet__sheet__arrow--down' : ''}`}>
            <FontAwesomeIcon icon="chevron-down" />
          </div>
          <div>
            {showFilters
              ? <><FontAwesomeIcon icon="eye-slash" /> {I18n.t('users.timesheets.hide_filters')}</>
              : <><FontAwesomeIcon icon="eye" /> {I18n.t('users.timesheets.show_filters')}</>
            }
          </div>
        </div>

        {/* Filters */ }
        <div className={`timesheet__filters ${showFilters ? '' : 'timesheet__filters--hidden'}`}>
          <div style={showFilters ? null : hide}>

            <div className="timesheet__filters__title">{I18n.t('users.timesheets.date_range')}</div>
            <SharedFlatpickr
              options={{
                mode: "range",
                inline: true,
                locale: I18n.locale
              }}
              value={range}
              onChange={onSelectDate}
            />
          </div>

          <div className="f-g-1 timesheet__filters--middle" style={showFilters ? null : hide}>
            <div className="timesheet__filters__title">{I18n.t('users.timesheets.selected_users')}</div>
            <TechnicianSelect
              teams    = {teams}
              onChange = {onSelectUser}
              prompt   = {I18n.t('users.timesheets.select_user')}
            />

          </div>

          <div className="d-flex f-column j-c-between f-item-15">
            <div style={showFilters ? null : hide}>
              {/* Export actions */}
              <div className="timesheet__filters__title">
                {I18n.t('users.timesheets.actions')}
              </div>

              <div className="timesheet__actions">
                <a href={csvPath}>
                  <FontAwesomeIcon icon="file-csv" />
                  &nbsp;
                  {I18n.t('users.timesheets.csv_export')}
                </a>
                { overtimePath &&
                  <a href={overtimePath}>
                    <FontAwesomeIcon icon="file-csv" />
                    &nbsp;
                    {I18n.t('users.timesheets.overtime')}
                  </a>
                }
                <a href="#" onClick={this.triggerPrint}>
                  <FontAwesomeIcon icon="print" />
                  &nbsp;
                  {I18n.t('users.timesheets.print')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
